.official-title{
  font-size: 3.2vh;
  font-weight: bold;
}

.official-card{
  color: #225FBA;
  text-align: center;
  font-weight: bolder;
  font-size: 130%;
}

.unofficial-card{
  text-align: center;
  font-weight: bolder;
  font-size: 130%;
}

.initial-card{
  text-align: center;
  font-weight: bolder;
  font-size: 130%;
}



.recent-card{
  text-align: center;
  font-weight: bolder;
  font-size: 130%;
}

.sport-name{
  color: #006D75;
}

.unofficial-title{
  text-align: left;
  color: #225FBA;
  font-size: 3.2vh;
  font-weight: bold;
}

.initial-title{
  font-size: 3vh;
  font-weight: bold;
}

.add-sport-title{
  font-size: x-large;
}


.profile-sport-list-item{
  cursor: default !important;
  color: black !important;
}

.unofficial-explanation{
  border-bottom: 1px dotted #225FBA;
  padding-bottom: 1px !important;
}

.athlete-five-highest{
  font-size: 3vh;
}

.athlete-details{
  font-size: 3vh;
  color: #225FBA !important;
}

.rating{
  color: #225FBA;
  font-weight: bolder;
}

.table-striped tbody tr:nth-of-type(odd){
  background-color: rgb(245,245,245);
}

.table-striped tbody tr:nth-of-type(odd):hover{
  background-color: rgb(0,101,255,.15);
}

.table-striped tbody tr:nth-of-type(even):hover{
  background-color: rgb(0,101,255,.15);
}

.opponents-played{
  color: black;
}

.games-played{
  color: black;
}

.sport-name{
  text-align: justify;
}

.accordion-button{
   border: 0px;
   background-color: #fff;
}

.odd > .accordion-button{
  background-color: inherit;
}

.odd > .accordion-button:hover{
  background-color: inherit;
}

.odd > .accordion-button.collapsed::before{
  color: black !important;
  content: ' ';
  font-size: 16px;
}

.odd > .accordion-button[aria-expanded="true"]::before {
  color: black !important;
  content: "▼ ";
  font-size: 16px;
}

.even > .accordion-button.collapsed::before{
  color: black !important;
  content: '► ';
  font-size: 16px;
}

.even > .accordion-button[aria-expanded="true"]::before {
  color: black !important;
  content: "▼ ";
  font-size: 16px;
}

.sport-individual-games{
  all: unset;
}

.sport-individual-games > .accordion-button{
  background-color: inherit;
}

.sport-individual-games > .accordion-button.collapsed::before{
  color: black !important;
  content: '► ';
  font-size: 16px;
}

.sport-individual-games > .accordion-button[aria-expanded="true"]::before {
  color: black !important;
}

.athlete-statement{
  font-size: 3.5vh;
   color: #225FBA !important;
   font-weight: bolder;
}

.how-to-official{
  font-weight: normal;
}

.athlete-statement-words{
  font-weight: bold;
}

.accordion-button:focus{
  outline: none;
}

.accordion-button.collapsed::before{
  color: #007881 !important;
  content: '► ';
}

.accordion-button[aria-expanded="true"]::before {
  color: #007881 !important;
  content: "▼ ";
}

.breakdown{
  font-size: 16px;
  font-weight: bold;
}

.spinner-position{
  position: absolute;
  top: 50%;
  left: 50%;
}
